const HODDER_EDUCATION = 'hoddereducation';
const RISING_STARS = 'risingstars';
const GALORE_PARK = 'galorepark';
const SINGAPORE = 'singapore';
const GIBSON = 'HodderGibson';

export const SITE_NAMES = {
  HODDER_EDUCATION,
  RISING_STARS,
  GALORE_PARK,
  SINGAPORE,
  GIBSON,
};
