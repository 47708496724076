import { SITE_NAMES } from '@js/constants';

// todo: remove after https://github.com/webpack-contrib/mini-css-extract-plugin/issues/34 resolved
if (process.env.NODE_ENV !== 'production') {
  switch (process.env.SITE_NAME) {
    default:
    case SITE_NAMES.HODDER_EDUCATION: {
      import('../sass/hodder-education/main.scss');
      break;
    }

    case SITE_NAMES.GIBSON: {
      import('../sass/gibson/main.scss');
      break;
    }

    case SITE_NAMES.RISING_STARS: {
      import('../sass/rising-stars/main.scss');
      break;
    }

    case SITE_NAMES.SINGAPORE: {
      import('../sass/singapore/main.scss');
      break;
    }

    case SITE_NAMES.GALORE_PARK: {
      import('../sass/galore-park/main.scss');
      break;
    }
  }
}

import(/* webpackChunkName: "components" */ './components');

// Load react only if we have components on the page.
const components = document.querySelectorAll('[data-component]');

if (components.length) {
  import(/* webpackChunkName: "app" */ './react');
}
